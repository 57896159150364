import request from '@/axios'

export const toolsAddressTreeApi = (): Promise<IResponse> => {
  return request.get({ url: '/tools/addressTree' })
}
export const toolsUploadListApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/tools/upload/list', params: data })
}
export const toolsUploadDeleteApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/delete', data })
}
export const toolsUploadRemoveApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/removeGroup', data })
}
// /tools/upload/updateName
export const toolsUploadUpdateNameApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/updateName', data })
}
export const toolsUploadGroupListApi = (params): Promise<IResponse> => {
  return request.get({ url: '/tools/upload/groupList', params })
}

export const toolsUploadEditGroupApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/editGroup', data })
}
export const toolsAddGroupApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/addGroup', data })
}
export const toolsUploadDeleteGroupApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/upload/deleteGroup', data })
}
export const addressByOne = (params: any): Promise<IResponse> => {
  return request.get({ url: '/tools/addressByOne', params })
}

export const logsApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/tools/logs', params })
}
