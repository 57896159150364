<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'
import RecordText from '@/views/Dashboard/components/recordText/recordText.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { includes } from 'lodash-es'
const route = useRoute()
const whitList = ['/login', '/platformLogin']
function unShow() {
  return !whitList.includes(route.path)
}
whitList.includes(route.path)

const { getPrefixCls } = useDesign()
const RecordTextRef = ref()
function openRecordText() {
  RecordTextRef.value.openDialog()
}
const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)
appStore.initTheme()
</script>

<template>
  <ConfigGlobal :size="currentSize">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
  <el-button v-if="unShow()" class="btn" type="success" @click="openRecordText">记事本</el-button>
  <RecordText ref="RecordTextRef" />
</template>
<style lang="less" scoped>
.btn {
  position: fixed;
  bottom: 20px;
  left: 160px;
  width: 100px;
  padding: 24px 60px;
  font-size: 24px;
}
</style>
<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}

.el-input-number .el-input__wrapper {
  padding: 0 !important;
}

.preview-box {
  &.alert {
    position: relative;
    width: 350px;
    height: 520px;
    padding: 20px 5px;
    overflow: auto;
    border: 5px solid #d9d9d9;
    border-radius: 40px;
    box-sizing: border-box;
    scrollbar-width: none;
  }

  img {
    max-width: 100% !important;
  }

  video {
    max-width: 100% !important;
  }
}

.el-select__input {
  width: 100% !important;
}
</style>
