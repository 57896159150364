<script setup lang="ts">
const slots = defineSlots<{
  prefix?: any
  suffix?: any
  append?: any
  prepend?: any
}>()
let {
  isFloat = true,
  precision = 2,
  attributes = {},
  min,
  max = 999999999,
  allowNegative = false,
  placeholder = '数值',
  disabled = false,
  maxLength = undefined,
  isString = false,
  defaultValue = ''
} = defineProps<{
  isFloat?: boolean // 是否是整数
  allowNegative?: boolean // 是否允许负数
  precision?: number // 小数点后位数
  attributes?: any // 其他属性
  min?: number | undefined // 最小值
  max?: number | undefined // 最大值
  placeholder?: string | undefined
  disabled?: boolean
  maxLength?: number
  isString?: boolean
  defaultValue?: any
}>()

const model = defineModel<any>()
const emits = defineEmits<{
  (e: 'input', val: any): void
}>()

const blur = () => {
  // 触发change事件
  if (!isFloat) {
    if (model.value === '-') {
      model.value = ''
    }
  } else {
    if (model.value) {
      model.value = (model.value * 1).toFixed(2)
    }
  }
}
const inputChange = (val: any) => {
  console.log(val, '上岛咖啡到时看看')
  let hasNegative = false
  // 是否允许负数
  if (allowNegative) {
    // 是否是负数
    if (val.startsWith('-')) {
      hasNegative = true
      val = val.slice(1)
    }
  } else {
  }
  if (!isFloat) {
    val = val.replace(/[^0-9]/g, '')
    if (val === '') {
      if (hasNegative) {
        model.value = '-'
      } else {
        model.value = ''
      }
    } else {
      if (hasNegative) {
        val = '-' + val
      }
      // 小数点后位数
      // @ts-ignore
      if (isString) {
        if (maxLength) {
          model.value = val.slice(0, maxLength)
        }
      } else {
        val = val * 1
        if (min !== undefined) {
          model.value = Math.max(val, min)
        }
        if (max !== undefined) {
          model.value = Math.min(val, max ?? 999999999)
        }
      }
    }
  } else {
    if (val.includes('.')) {
      if (val.startsWith('.')) {
        model.value = ''
      } else {
        let [val1, val2] = val.split('.')
        // @ts-ignore
        val1 = val1.replace(/[^0-9]/g, '').slice(0, 9)
        val2 = val2.replace(/[^0-9]/g, '')
        if (val2 === '') {
          model.value = [val1, val2].join('.')
        } else {
          model.value = val1 + '.' + val2.slice(0, precision)
        }
      }
    } else {
      model.value = val.replace(/[^0-9]/g, '').slice(0, 9)
    }
  }
  if (!val && defaultValue !== '') {
    model.value = defaultValue
  }
  emits('input', model.value)
  return false
}
</script>

<template>
  <ElInput
    v-model="model"
    :bind="{ ...attributes }"
    @input="inputChange"
    @blur="blur"
    :clearable="true"
    :placeholder="placeholder ?? '数值'"
    :disabled="disabled"
  >
    <template #suffix v-if="slots.suffix">
      <slot name="suffix"></slot>
    </template>
    <template #prefix v-if="slots.prefix"><slot name="prefix"></slot> </template>
    <template #append v-if="slots.append"><slot name="append"></slot> </template>
    <template #prepend v-if="slots.prepend"><slot name="prepend"></slot> </template>
  </ElInput>
</template>

<style scoped lang="less"></style>
