import request from '@/axios'

export const infoApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/business/info', params })
}
export const listApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/business/index', params })
}
export const switchApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/switch', data })
}
export const switchSingleApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/setSingleOperateState', data })
}
export const switchOperateApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/setOperateState', data })
}

export const auditApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/exa', data })
}
export const updateApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/update', data })
}
export const createApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/create', data })
}
export const deleteApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/business/delete', data })
}
export const accountApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/business/account', params })
}

export const setPasswordApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/business/setPassword', data: params })
}
export const editBankApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/business/editBank', data: params })
}
export const setShareApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/business/setShareName', data: params })
}

// 仓库备注列表
export const operationListApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/business/operationList', params })
}

//仓库添加备注
export const addOperationApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/business/addOperation', data: params })
}

export const setAdminApi = (data: any): Promise<IResponse> =>
  request.post({ url: '/business/setAdmin', data })
