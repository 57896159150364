import request from '@/axios'

export const createApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/create', data })
}
export const infoApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/user/info', params })
}
export const listApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/user/index', params })
}
export const switchApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/goods/cate/switch', data })
}
export const updateApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/update', data })
}
export const statisticsApi = (params?: any): Promise<IResponse> => {
  return request.get({ url: '/user/statistics', params })
}
export const getTagApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/user/getTag', params })
}
export const getUserTagApi = (params?: any): Promise<IResponse> => {
  return request.get({ url: '/user/getUserTag', params })
}
export const setTagApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/setTag', data })
}

export const delUserTagApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/delUserTag', data })
}
export const sendMsgApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/sendMsg', data })
}

export const operationApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/user/getOperationForUser', params })
}
export const giveIntegralApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/giveIntegral', data })
}
export const setLevelApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/setLevel', data })
}
export const addRemarkApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/addRemark', data })
}
export const setBlackApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/setBlack', data })
}
export const setWhiteApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/setFaultWhiteList', data })
}
export const offlinePayApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/offlinePay', data })
}
export const integralLog = (params: any): Promise<IResponse> => {
  return request.get({ url: '/user/integralLog', params })
}

export const setAdminApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/setAdmin', data })
}
export const updatePlatformName = (data: any): Promise<IResponse> => {
  return request.post({ url: '/user/updatePlatformName', data })
}

export const giveCouponApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/user/giveCoupon', data: params })
}

export const setPasswordApi = (params: any): Promise<IResponse> => {
  return request.post({ url: '/user/setPassword', data: params })
}
