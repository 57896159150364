import { listApi as brandListApi } from '@/api/mall/brand'
import { FormSchema } from '@/components/Form'
import { ref, reactive } from 'vue'
import { useSearch } from '@/hooks/web/useSearch'
import { remarkList, remarkTop, addRemark } from '@/api/mall/recordTxt'
import { ElMessage } from 'element-plus'
import { listApi as userListApi } from '@/api/mall/user'
import { listApi as adminListApi } from '@/api/mall/admin'

export function useSearchComp() {
  const { searchRegister } = useSearch()
  const isGrid = ref(false)
  const layout = ref('inline')
  const searchLoading = ref(false)
  const resetLoading = ref(false)
  const buttonPosition = ref('left')
  const userList = ref([])
  const visible = ref(false)
  const formLoading = ref(false)
  const TAGTYPE = [
    {
      label: '问售后'
    },
    {
      label: '问产品'
    },
    {
      label: '问价格'
    },
    {
      label: '问其他'
    }
  ]
  type FormType = {
    remark: string
    imgs: Array<any>
    tags: string
    is_top: 0 | 1
    user_id: string
  }
  const form: FormType = reactive({ remark: '', imgs: [], tags: '', is_top: 0, user_id: '' })
  const searchData = ref<any>({})
  const tableList = ref<any>([])
  const schema = reactive<FormSchema[]>([
    {
      field: 'tags',
      label: '备注标签',
      component: 'Select',
      componentProps: {
        options: TAGTYPE.map((i) => ({ label: i.label, value: i.label })),
        filterable: true
      }
    },
    {
      field: 'platform_nick_name',
      label: '平台昵称',
      component: 'Input'
    },
    {
      field: 'user_id',
      label: '记录员',
      component: 'Select',
      componentProps: {
        filterable: true
      },
      optionApi: async () => {
        const res = await adminListApi({ search_business_id: 0, page: 1, page_size: 1000 })
        return res?.data?.list?.map((item) => ({ value: item.id, label: item.username })) ?? []
      }
    },
    {
      field: 'start_time',
      label: '日期',
      component: 'DatePicker',
      componentProps: {
        type: 'datetimerange',
        rangeSeparator: '至',
        startPlaceholder: '开始日期',
        endPlaceholder: '结束日期',
        clearable: true,
        format: 'YYYY-MM-DD HH:mm:ss',
        valueFormat: 'YYYY-MM-DD HH:mm:ss'
      }
    }
  ])
  const getUserList = async () => {
    const { code, data } = await userListApi({ page: 1, page_size: 9999, exclude_business: 1 })
    if (code == 200) {
      userList.value = data?.list.map((i) => ({ label: i.nick_name, value: i.id }))
    }
  }
  const pagination = reactive({
    total: 0
  })
  const handleSearch = async (data: any) => {
    searchData.value = data
    const temp = {}
    for (const key in data) {
      temp[`search[${key}]`] = data[key]
      if (key === 'start_time') {
        temp[`search[start_time]`] = data?.start_time?.[0] || ''
        temp['search[end_time]'] = data?.start_time?.[1] || ''
      }
    }
    console.log('sjsjsj', temp)
    const params = {
      page: 1,
      page_size: 999,
      ...temp
    }
    const {
      code,
      data: { list }
    } = await remarkList(params)
    if (code == 200) {
      tableList.value = list
      console.log('实施计算机', { page: 1, page_size: 9999, ...data })
    }
  }
  const confirm = async () => {
    formLoading.value = true
    try {
      const { code } = await addRemark({ ...form, imgs: form.imgs.map((i) => i.id).join(',') })
      if (code == 200) {
        ElMessage({
          type: 'success',
          message: '保存成功'
        })
        handleSearch(searchData.value)
      }
    } catch (err) {
      console.log(err)
    } finally {
      formLoading.value = false
    }
  }
  const setTop = async (id: any, is_top) => {
    try {
      const { code } = await remarkTop({
        id,
        is_top
      })
      if (code == 200) {
        handleSearch(searchData.value)
      }
    } catch (err) {
      console.log(err)
    }
  }

  function openDialog() {
    visible.value = true
    handleSearch(searchData.value)
    getUserList()
  }
  function closeDialog() {
    visible.value = false
  }

  return {
    visible,
    pagination,
    userList,
    form,
    getUserList,
    confirm,
    setTop,
    tableList,
    TAGTYPE,
    searchRegister,
    handleSearch,
    isGrid,
    layout,
    schema,
    searchLoading,
    resetLoading,
    buttonPosition,
    searchData,
    openDialog,
    closeDialog,
    formLoading
  }
}
