<script lang="ts" setup>
import { useSearchComp } from './utils/hook'
import MyUpload from '@/components/MyUpload/MyUpload.vue'
import { Search } from '@/components/Search'

const {
  visible,
  form,
  confirm,
  userList,
  setTop,
  tableList,
  TAGTYPE,
  searchRegister,
  handleSearch,
  isGrid,
  schema,
  layout,
  formLoading,
  searchLoading,
  resetLoading,
  buttonPosition,
  openDialog,
  pagination,
  closeDialog
} = useSearchComp()

defineExpose({
  openDialog
})
</script>

<template>
  <el-dialog width="1600px" title="记事本" v-model="visible" @close="closeDialog">
    <Search
      :schema="schema"
      :is-col="isGrid"
      :layout="layout"
      :button-position="buttonPosition"
      :search-loading="searchLoading"
      :reset-loading="resetLoading"
      show-expand
      expand-field="field6"
      @search="handleSearch"
      @reset="handleSearch"
      @register="searchRegister"
    />
    <section class="table-box w-[100%]">
      <template v-if="tableList.length">
        <div class="table-item" v-for="(item, index) in tableList" :key="index">
          <div
            >用户：{{ item.user_id }} （账号）{{ item.tags }} {{ item.updated_at }}（记录员）</div
          >
          <div>
            <template v-for="(img, ind) in item.imgs_url" :key="ind">
              <el-image
                v-if="img"
                :src="img"
                class="mr-1 w-[100px] h-[100px]"
                :initial-index="ind"
                :preview-src-list="item.imgs_url"
                fit="cover"
                :z-index="9999"
                preview-teleported
              />
            </template>
          </div>
          <div>{{ item.remark }}</div>
          <div class="text-right">
            <el-button v-if="item.is_top === 0" @click="setTop(item.id, 1)">置顶</el-button>
            <el-button v-else @click="setTop(item.id, 0)">取消置顶</el-button>
          </div>
        </div>
      </template>
      <ElEmpty v-else description="暂无数据" />
    </section>
    <!-- <el-pagination background layout="prev, pager, next" :total="pagination.total" /> -->

    <section class="formArea p-6">
      <el-form v-model="form" v-loading="formLoading">
        <div class="flex justify-between">
          <el-form-item label="选择用户">
            <el-select v-model="form.user_id" clearable filterable class="w-[200px!important]">
              <el-option
                v-for="item in userList"
                :value="item.value"
                :label="item.label"
                :key="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-radio-group v-model="form.tags">
              <el-radio-button
                v-for="(item, index) in TAGTYPE"
                :key="index"
                :label="item.label"
                :value="item.label"
              />
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="" prop="remark">
          <el-input v-model="form.remark" :rows="4" type="textarea" placeholder="请输入文字" />
        </el-form-item>
        <div class="flex justify-between items-end">
          <el-form-item label="">
            <MyUpload v-model="form.imgs" :limit="6" hideUpload accept="image/*" />
            <span>可上传1-6张图片</span>
          </el-form-item>
          <el-button type="primary" @click="confirm">提交</el-button>
        </div>
      </el-form>
    </section>
  </el-dialog>
</template>

<style lang="less" scoped>
.table-box {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;

  .table-item {
    padding: 12px;
    border-bottom: 1px solid #333;
  }
}

.formArea {
  margin-top: 24px;
  border: 1px solid #333;
  border-radius: 8px;
}
</style>
