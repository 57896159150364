import request from '@/axios'
//备注列表
export const remarkList = (params: any): Promise<IResponse> => {
  return request.get({ url: '/tools/remark/homePageRemarkList', params })
}

//置顶/取消置顶
export const remarkTop = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/remark/homePageRemarkTop', data })
}

// 添加备注
export const addRemark = (data: any): Promise<IResponse> => {
  return request.post({ url: '/tools/remark/homePageAddRemark', data })
}
