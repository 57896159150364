<script setup lang="ts">
import { onBeforeUnmount, computed, PropType, unref, nextTick, ref, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { IDomEditor, IEditorConfig, i18nChangeLanguage } from '@wangeditor/editor'
import { propTypes } from '@/utils/propTypes'
import { isNumber } from '@/utils/is'
import { ElMessage } from 'element-plus'
import { useLocaleStore } from '@/store/modules/locale'
import imageLibrary from '@/components/imageLibrary/imageLibrary.vue'

import { PATH_URL } from '@/axios/service'
import { useUserStoreWithOut } from '@/store/modules/user'
const userStore = useUserStoreWithOut()
const ImageLibrary = ref()

const localeStore = useLocaleStore()

const currentLocale = computed(() => localeStore.getCurrentLocale)

i18nChangeLanguage(unref(currentLocale).lang)

const props = defineProps({
  editorId: propTypes.string.def('wangeEditor-1'),
  height: propTypes.oneOfType([Number, String]).def('440px'),
  editorConfig: {
    type: Object as PropType<IEditorConfig>,
    default: () => undefined
  },
  modelValue: propTypes.string.def('')
})

const emit = defineEmits(['change', 'update:modelValue'])

// 编辑器实例，必须用 shallowRef
const editorRef = ref<IDomEditor>()

const valueHtml = ref('')
const defaultObj = [
  {
    type: 'paragraph',
    lineHeight: '1.5',
    children: [
      {
        text: '',
        fontSize: '18px',
        fontFamily: '黑体'
      }
    ]
  }
]
const defaultContent = ref(defaultObj)
watch(
  () => props.modelValue,
  (val: string) => {
    if (val === unref(valueHtml)) return
    nextTick(() => {
      valueHtml.value = val
      defaultContent.value = defaultObj
    })
  },
  {
    immediate: true,
    deep: true
  }
)

// 监听
watch(
  () => valueHtml.value,
  (val: string) => {
    emit('update:modelValue', val)
  },
  {
    immediate: true,
    deep: true
  }
)

const handleCreated = (editor: IDomEditor) => {
  editorRef.value = editor
}
const recieveImageResult = (result) => {
  console.log(result)
}

// 编辑器配置
const editorConfig = computed((): IEditorConfig => {
  return Object.assign(
    {
      readOnly: false,
      customAlert: (s: string, t: string) => {
        switch (t) {
          case 'success':
            ElMessage.success(s)
            break
          case 'info':
            ElMessage.info(s)
            break
          case 'warning':
            ElMessage.warning(s)
            break
          case 'error':
            ElMessage.error(s)
            break
          default:
            ElMessage.info(s)
            break
        }
      },
      autoFocus: false,
      scroll: true,
      uploadImgShowBase64: true,
      MENU_CONF: {
        uploadImage: {
          customBrowseAndUpload: async () => {
            if (ImageLibrary.value) {
              try {
                const result = await ImageLibrary.value.openLibrary() // 等待素材库返回结果
                const plainResult = JSON.parse(JSON.stringify(result))
                console.log('转换后的图片数据：', plainResult)
                let imagesHtml = ''
                plainResult.forEach((image) => {
                  if (image.url) {
                    // 构建多个图片的 HTML 字符串
                    imagesHtml += `<img src="${image.url}" alt="${image.alt || ''}" />`
                  }
                })
                unref(editorRef.value)?.dangerouslyInsertHtml(imagesHtml)
              } catch (error) {
                console.error('图片选择失败:', error)
              }
            } else {
              console.error('ImageLibrary 未正确初始化')
            }
          }
        },
        uploadVideo: {
          server: PATH_URL + '/tools/upload/uploadContent',
          fieldName: 'file',
          headers: {
            [userStore.getTokenKey ?? 'Authorization']: userStore.getToken ?? ''
          },
          onSuccess(file: File, res: any) {
            console.log(`${file.name} 上传成功`, res)
          },
          // 上传进度的回调函数
          onProgress(progress: number) {
            console.log(progress)
          }
        },
        fontSize: {
          fontSizeList: [
            '12px',
            '14px',
            '16px',
            '18px',
            '20px',
            '24px',
            '28px',
            '32px',
            '36px',
            '40px'
          ]
        }
      }
    },
    props.editorConfig || { uploadImgMaxSize: 1024 * 1024 * 10 }
  )
})

const editorStyle = computed(() => {
  return {
    height: isNumber(props.height) ? `${props.height}px` : props.height
  }
})

// 回调函数
const handleChange = (editor: IDomEditor) => {
  emit('change', editor)
}

// 组件销毁时，及时销毁编辑器
onBeforeUnmount(() => {
  const editor = unref(editorRef.value)

  // 销毁，并移除 editor
  editor?.destroy()
})

const getEditorRef = async (): Promise<IDomEditor> => {
  await nextTick()
  return unref(editorRef.value) as IDomEditor
}

defineExpose({
  getEditorRef
})
</script>

<template>
  <div class="border-1 border-solid border-[var(--el-border-color)] z-10">
    <!-- 工具栏 -->
    <Toolbar
      :editor="editorRef"
      :editorId="editorId"
      :toolbarConfig="{
        toolbarKeys: [
          'bold', // 默认按钮
          'italic' // 默认按钮
        ]
      }"
      class="border-0 b-b-1 border-solid border-[var(--el-border-color)]"
    />
    <!-- 编辑器 -->
    <Editor
      v-model="valueHtml"
      :editorId="editorId"
      :defaultConfig="editorConfig"
      :defaultContent="defaultContent"
      :style="editorStyle"
      class="editor_content"
      @on-change="handleChange"
      @on-created="handleCreated"
    />
    <imageLibrary ref="ImageLibrary" :limit="9" :recieveImageResult="recieveImageResult" />
  </div>
</template>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.editor_content {
  :deep(video) {
    height: auto;
    max-width: 90%;
  }

  :deep(img) {
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
