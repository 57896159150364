import request from '@/axios'

export const settingUploadApi = (): Promise<IResponse> => {
  return request.get({ url: '/setting/upload' })
}
export const settingUpdateUploadApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/setting/updateUpload', data })
}
export const faultUpdateFaultApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/fault/updateFault', data })
}
export const faultFaultApi = (): Promise<IResponse> => {
  return request.get({ url: '/fault/fault' })
}

export const settingAgreementApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/setting/agreement', params: data })
}

export const settingUpdateAgreementApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/setting/updateAgreement', data })
}

export const createNoticeApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/notice/create', data })
}
export const noticeIndexApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/notice/index', params: data })
}
export const noticeSwitchApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/notice/switch', data })
}
export const noticeDeleteApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/notice/delete', data })
}
export const switchNoticeApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/notice/switch', data })
}
export const noticeInfoApi = (data: any): Promise<IResponse> => {
  return request.get({ url: '/notice/info', params: data })
}

export const noticeUpdateApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/notice/update', data })
}
export const settingUpdateServiceApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/setting/updateService', data })
}
export const settingServiceApi = (): Promise<IResponse> => {
  return request.get({ url: '/setting/service' })
}

export const settingOssApi = (): Promise<IResponse> => {
  return request.get({ url: '/setting/oss' })
}
export const settingUpdateOssApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/setting/updateOss', data })
}

export const getEvaluateSettingApi = (): Promise<IResponse> => {
  return request.get({ url: '/evaluate/getSetting' })
}

export const updateEvaluateSettingApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/evaluate/setSetting', data })
}
// /setting/updateAfAgreement
export const updateAfAgreementApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/setting/updateAfAgreement', data })
}
// /setting/getAfAgreement
export const getAfAgreementApi = (): Promise<IResponse> => {
  return request.get({ url: '/setting/getAfAgreement' })
}
