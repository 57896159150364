import request from '@/axios'

export const createApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/create', data })
}
export const infoApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/admin/info', params })
}
export const listApi = (params: any): Promise<IResponse> => {
  return request.get({ url: '/admin/index', params })
}
export const switchApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/switch', data })
}
export const deleteApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/delete', data })
}
export const updateApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/update', data })
}

// /admin/changePassword
export const changePasswordApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/changePassword', data })
}
export const sendCodeApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/sendCode', data })
}

// /sendCode
export const loginBySmsCodeApi = (data: any): Promise<IResponse> => {
  return request.post({ url: '/loginBySmsCode', data })
}

export const setOrderPer = (data: any): Promise<IResponse> => {
  return request.post({ url: '/admin/setOrderPer', data })
}
