<template>
  <el-dialog v-model="dialogVisible">
    <img class="w-full" :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
  <el-dialog
    v-model="dialogTable"
    class="min-w-[1000px] h-[850px]"
    title="素材管理"
    @close="cancel"
  >
    <div>
      <div class="flex justify-between" v-if="!userStore.userInfo?.admin?.business_id">
        <div class="flex"
          ><span class="w-[80px]">所属仓库：</span>
          <div class="w-[200px]">
            <el-select
              placeholder="仓库"
              filterable
              clearable
              v-model="form.business_id"
              class="mx-1"
              @change="getGroups"
            >
              <el-option
                :label="item.label"
                :value="item.value"
                v-for="item in businessList"
                :key="item.value"
              />
            </el-select> </div
        ></div>

        <el-radio-group v-model="form.type" @change="typeChange">
          <el-radio-button :value="item.value" v-for="item in FILTER_TYPE" :key="item.value">{{
            item.label
          }}</el-radio-button>
        </el-radio-group>
      </div>

      <div class="flex">
        <div class="border-solid border-1 border-[#dcdfe6] p-2 rounded-6px mt-2 mr-2 w-[200px]">
          <div class="flex justify-between items-center px-2"
            ><div
              class="hover:cursor-pointer flex items-center text-xl"
              :class="{ 'text-blue': form.group_id == -1 }"
              @click="menuSelect(-1)"
              >全部</div
            ><div>共{{ groups.all_count }}个资源</div></div
          >
          <!--          分组menu-->
          <el-menu
            class="h-[550px] overflow-auto"
            style="border: none"
            @select="menuSelect"
            :unique-opened="true"
            :default-active="`${form.group_id}`"
          >
            <el-menu-item @click="menuSelect(0)">
              <span :class="{ 'text-blue': !form.group_id }"
                >默认分组(<span class="text-blue">{{ groups.default_count }}</span
                >)</span
              >
            </el-menu-item>
            <template v-for="item in groups.list" :key="item.id">
              <el-sub-menu v-if="item.children?.length" :index="item.id">
                <template #title
                  ><div
                    class="flex items-center justify-between w-full"
                    :class="{ 'text-blue': form.group_id == item.id }"
                    @click="menuSelect(item.id)"
                  >
                    <div>
                      {{ item.label }}(<span class="text-blue">{{ item.count }}</span
                      >) </div
                    ><span
                      class="text-[12px] text-blue hover:cursor-pointer"
                      @click.stop="addGroup(item)"
                      >添加</span
                    >
                  </div></template
                >
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.id"
                  :index="subItem.id"
                >
                  <el-tooltip>
                    <template #content
                      >{{ subItem.label
                      }}<span class="text-blue">({{ subItem.count }})</span></template
                    >
                    <div>
                      <span>{{ subItem.label }}</span
                      ><span class="text-blue">({{ subItem.count }})</span>
                    </div>
                  </el-tooltip>
                </el-menu-item>
              </el-sub-menu>
              <el-menu-item
                v-else
                :index="item.id"
                class="flex items-center justify-between"
                :class="{ 'text-blue': form.group_id == item.id }"
                @click="menuSelect(item.id)"
              >
                <el-tooltip>
                  <template #content
                    >{{ item.label }}<span class="text-blue">({{ item.count }})</span></template
                  >
                  <div>
                    <span>{{ item.label }}</span
                    ><span class="text-blue">({{ item.count }})</span>
                  </div>
                </el-tooltip>

                <span
                  class="text-[12px] text-blue hover:cursor-pointer ml-1 mr-[22px]"
                  @click.stop="addGroup(item)"
                  >添加</span
                >
              </el-menu-item>
            </template>
          </el-menu>
          <div class="text-[12px] text-blue hover:cursor-pointer text-center" @click="addGroup(0)"
            >添加分组</div
          >
        </div>
        <div class="border-solid border-1 border-[#dcdfe6] mt-2 p-2 flex-1">
          <div class="flex">
            <el-upload
              ref="upload"
              class="hide-my-upload"
              v-model:file-list="model"
              :class="{ hideUpload: false }"
              :action="url"
              :headers="headers"
              :list-type="props.listType"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :limit="9"
              :disabled="props.disabled"
              :multiple="props.multiple"
              :show-file-list="false"
              :drag="props.drag"
              :accept="acceptType"
              :on-success="handleSuccess"
              :on-error="handleError"
              :before-remove="handleBeforeRemove"
              :before-upload="handleBeforeUpload"
              :data="{
                group_id: form.group_id || 0,
                permanent: props.permanent || 1,
                business_id: form.business_id || 0
              }"
              :auto-upload="true"
              :on-exceed="handleExceed"
              ><el-button type="primary" class="text-center"
                >添加{{ FILTER_TYPE.find((item) => form.type == item.value)?.label }}</el-button
              ></el-upload
            >
            <div class="mx-2 flex-1">
              <el-input placeholder="文件名" v-model="form.search_name" />
            </div>
            <el-cascader
              :options="groups.list"
              placeholder="分组"
              class="flex-1"
              v-model="form.group_id"
              :props="casProps"
              clearable
            />
            <el-button type="primary" @click="search" class="ml-2">搜索</el-button>
            <el-button style="margin-left: 8px !important" @click="reset">重置</el-button>
          </div>
          <div v-if="['picture', 'video'].includes(form.type)"
            >{{ form.type == 'picture' ? '图片' : '视频' }}大小不要超过{{
              (
                (form.type == 'picture'
                  ? uploadConfig?.image_max_size
                  : uploadConfig?.video_max_size) / 1024
              ).toFixed(2)
            }}M</div
          >
          <div class="my-1 flex"
            ><div class="flex items-center"
              ><el-checkbox label="全部" v-model="isSelectAll" @change="selectAllChange"
                >当前页全选</el-checkbox
              ><span class="mx-1">|</span>已选<el-tooltip>
                <template #content
                  >选中项id: {{ selectArr.map((item) => item.id).join(',') }}</template
                >
                {{ selectArr.length }}</el-tooltip
              >项
              <span class="text-blue hover:cursor-pointer ml-1 mr-[22px]" @click="clearSelect"
                >清空</span
              ></div
            >
            <el-button
              class="ml-1"
              type="warning"
              :disabled="!selectArr.length"
              @click="dialogRemove = true"
              >修改分组</el-button
            >
            <el-button
              style="margin-left: 4px !important"
              type="danger"
              :disabled="!selectArr.length"
              @click="dels"
              >彻底删除</el-button
            >
          </div>
          <div class="flex flex-wrap mt-1 overflow-auto h-[460px] w-full" v-if="listData.total">
            <div
              :class="[form.type == 'picture' ? 'w-1/4' : 'w-1/2']"
              :key="item.id"
              v-for="item in listData.list"
            >
              <div class="flex justify-between items-end">
                <el-image
                  class="w-full mr-1 h-[100px]"
                  object-fit="contain"
                  :src="item!.url"
                  v-if="form.type == 'picture'"
                  :preview-src-list="[item.url]"
                />
                <audio v-else-if="form.type == 'audio'" class="w-[90%] mr-1" controls>
                  <source :src="item!.url" :type="item.file_type" />
                </audio>
                <el-button
                  v-else-if="form.type == 'other'"
                  class="w-[90%] mr-1"
                  @click="downFile(item.url)"
                >
                  点击下查看
                </el-button>
                <video v-else-if="form.type == 'video'" class="w-[90%] mr-1 h-[200px]" controls>
                  <source :src="item!.url" :type="item.file_type" />
                </video>
                <el-checkbox
                  :value="item"
                  :key="item.id"
                  v-model="item.isSelected"
                  class="h-[12px!important]"
                  @change="(val) => selectArrChange(val, item)"
                />
              </div>
              <div>
                <el-tooltip>
                  <template #content
                    >{{ item?.file_name_arr?.[0] }}.{{ item?.file_name_arr?.[1] }}</template
                  >
                  <div class="flex items-center">
                    <el-input
                      :placeholder="`文件名${item?.file_name_arr?.[0]}`"
                      class="my-1"
                      :class="{
                        'none-border': item.id != noBorder
                      }"
                      @focus="noBorder = item.id"
                      @blur="noBorder = ''"
                      v-model="item.file_name_arr[0]"
                      @input="updateName(item)"
                    />
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="m-[100px] text-center" v-else>暂无素材</div>
          <el-pagination
            v-model:current-page="form.page"
            v-model:page-size="form.page_size"
            @size-change="sizeChange"
            @current-change="currentChange"
            :page-sizes="pagination.pageSizes"
            layout="sizes, prev, pager, next"
            :total="listData.total"
            :show-total="true"
            hide-on-single-page
          />
        </div>
      </div>
      <div class="text-center mt-[6px]"
        ><el-button @click="cancel">关闭</el-button
        ><el-button @click="confirm" type="primary">确定</el-button></div
      >
    </div></el-dialog
  >
  <el-dialog v-model="dialogRemove" title="移动素材" class="w-[600px!important]" center>
    <el-form class="w-[500px]">
      <el-form-item label="目标分组">
        <el-cascader
          :options="groups.list"
          placeholder="分组"
          class="flex-1"
          v-model="to_group_id"
          :props="casProps"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialogRemove = false">取消</el-button>
      <el-button type="primary" @click="removeTo">确定</el-button>
    </template>
  </el-dialog>
  <el-dialog v-model="dialogRename" title="添加分组" class="w-[400px!important]" center>
    <el-form class="w-[300px]" label-width="120px">
      <el-form-item label="分组名称" class="mb-[20px!important]">
        <ElInput placeholder="分组名称" class="flex-1" v-model="renameParasm.group_name" />
      </el-form-item>
      <el-form-item label="分组排序">
        <MyInput v-model="renameParasm.sort" placeholder="排序" class="mb-2"
      /></el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="dialogRename = false">取消</el-button>
      <el-button type="primary" @click="submitAddGroup">确定</el-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import {
  ElMessage,
  ElMessageBox,
  genFileId,
  UploadInstance,
  UploadProps,
  UploadRawFile
} from 'element-plus'
import { useUserStoreWithOut } from '@/store/modules/user'
import { downFile, useImageSize } from '@/utils'
import {
  toolsAddGroupApi,
  toolsUploadDeleteApi,
  toolsUploadGroupListApi,
  toolsUploadListApi,
  toolsUploadRemoveApi,
  toolsUploadUpdateNameApi
} from '@/api/tools'
import { listApi } from '@/api/mall/business'
import { useDebounceFn } from '@vueuse/core'
import { settingUploadApi } from '@/api/setting'
import MyInput from '@/components/MyInput/MyInput.vue'
import { FILTER_TYPE } from '@/constants'
type ImageSizeLimit = {
  w?: number //宽度 add
  h?: number // 高度
  minW?: number // 最小宽度
  maxW?: number // 最大宽度
  minH?: number // 最小高度
  maxH?: number // 最大高度
}
const dialogTable = ref(false)
const casProps = ref({
  emitPath: false,
  checkStrictly: true
})
const props = withDefaults(
  defineProps<{
    limit?: number
    disabled?: boolean
    multiple?: boolean
    showFileList?: boolean
    drag?: boolean
    accept?: string
    listType?: 'text' | 'picture' | 'picture-card'
    size?: number
    sizes?: ImageSizeLimit
    data?: Record<string, any>
    cardsize?: any
    group?: string
    permanent?: any //是否永久  0  正常用这个 1   富文本专用
    hideUpload?: boolean
    remark?: string
    type: string
  }>(),
  {
    limit: 1,
    disabled: false,
    multiple: false,
    showFileList: true,
    drag: true,
    listType: 'picture-card',
    group: '0',
    permanent: 0,
    hideUpload: false,
    type: 'picture'
  }
)

const form = ref({
  page: 1,
  page_size: 20,
  type: props.type || 'picture',
  group_id: -1,
  business_id: '',
  search_name: ''
})
const isSelectAll = ref(false)
const noBorder = ref('')
const selectAllChange = () => {
  if (!Array.isArray(selectArr.value)) {
    selectArr.value = selectArr.value ? [selectArr.value] : []
  }
  if (listData.value.list.length) {
    if (isSelectAll.value) {
      const tmp = listData.value.list.filter(
        (item: any) => !selectArr.value.map((item: any) => item.id).includes(item?.id)
      )
      tmp.forEach((item: any) => {
        item.isSelected = true
      })
      //@ts-ignore
      selectArr.value.push(...tmp)
    } else {
      listData.value.list.forEach((item: any) => {
        item.isSelected = false
      })
      selectArr.value = selectArr.value.filter((item: any) => item.isSelected)
    }
  }
}
const selectArrChange = (val, item) => {
  if (val) {
    selectArr.value.push(item)
  } else {
    selectArr.value = selectArr.value.filter((it) => it.id != item.id)
  }
  if (selectArr.value.length == listData.value.list.length) {
    isSelectAll.value =
      listData.value.list.length == listData.value.filter((item) => item.isSelected).length
  } else {
    isSelectAll.value = false
  }
}
const clearSelect = () => {
  selectArr.value = []
  listData.value.list.forEach((item) => {
    item.isSelected = false
  })
}
const acceptType = ref()
const limitSize = ref()
const typeChange = () => {
  getGroups()
  search()
  if (form.value.type == 'picture') {
    acceptType.value =
      props.accept ||
      uploadConfig.value.type
        .filter((item) => item.switch)
        .map((item) => `image/${item.type}`)
        .join(',')
    limitSize.value = props.size || uploadConfig.value?.image_max_size
  } else {
    acceptType.value =
      props.accept ||
      uploadConfig.value.video_type
        .filter((item) => item.switch)
        .map((item) => `video/${item.type}`)
        .join(',')
    limitSize.value = props.size || uploadConfig.value?.video_max_size
  }
}
const reset = () => {
  form.value = {
    page: 1,
    page_size: 20,
    type: 'picture',
    group_id: -1,
    business_id: '',
    search_name: ''
  }
}

let resolvePromise = (value: unknown) => {
  console.log(value)
}
const openLibrary = () => {
  dialogTable.value = true
  return new Promise((resolve) => {
    resolvePromise = resolve
  })
}
const cancel = () => {
  dialogTable.value = false
  model.value = []
  if (resolvePromise) {
    resolvePromise([]) // 返回空数组表示取消
  }
}
const confirm = async () => {
  if (selectArr.value.length > (props.limit || 9)) {
    ElMessage.error(`最多选择${props.limit}个素材`)
    return
  }

  model.value = selectArr.value.map((item: any) => {
    return { id: item.id, url: item.url }
  })

  // 通过 resolve 返回数据
  if (resolvePromise) {
    resolvePromise(model.value)
  }

  dialogTable.value = false
}
const search = async () => {
  form.value.page = 1
  getList()
}
const sizeChange = (val: number) => {
  form.value.page_size = val
  getList()
}
const currentChange = (val: number) => {
  form.value.page = val
  getList()
}

const dialogRemove = ref(false)

const menuSelect = (index: string | number) => {
  //@ts-ignore
  form.value.group_id = index * 1
  getList()
}

const userStore = useUserStoreWithOut()
const selectArr = ref<any>([])
const fileNameArr = (file) => {
  // 把行如 a.c.png 转成 ['a.c','png']
  const tmp = file.split('.')
  return [tmp.slice(0, -1).join('.'), tmp[tmp.length - 1]]
}
const updateName = useDebounceFn(async (item) => {
  if (item.file_name_arr[0] === '') {
    ElMessage.error('文件名不能为空')
    return (item.file_name_arr = fileNameArr(item.file_name))
  }
  const { code, message } = await toolsUploadUpdateNameApi({
    id: item.id,
    file_name: `${item.file_name_arr[0]}.${item.file_name_arr[1]}`,
    business_id: form.value.business_id || 0
  })
  if (code === 200) {
  } else {
    ElMessage({
      type: 'error',
      message: message
    })
  }
}, 2000)
const dialogRename = ref(false)
const renameParasm = ref({ pid: '', group_name: '', sort: '' })
const addGroup = async (item) => {
  dialogRename.value = true
  renameParasm.value.pid = item.id || 0
}
const submitAddGroup = async () => {
  if (!renameParasm.value.group_name) return ElMessage({ type: 'error', message: '请输入分组名称' })
  if (!renameParasm.value.sort) return ElMessage({ type: 'error', message: '请输入排序' })
  const { code } = await toolsAddGroupApi(renameParasm.value)
  if (code === 200) {
    ElMessage({
      type: 'success',
      message: '添加分组成功'
    })
    dialogRename.value = false
    getGroups()
  }
}
const headers = ref({
  Authorization: userStore.getToken ?? ''
})
const groups = ref<any>([])
const getGroups = async () => {
  const res = await toolsUploadGroupListApi({
    business_id: form.value.business_id || 0,
    file_type: form.value.type == 'picture' ? 'image' : form.value.type
  })
  const replaceValuesAndLabels = (treeData, valueField, labelField) => {
    return treeData.map((item) => {
      return {
        ...item,
        value: item[valueField],
        label: item[labelField],
        disabled: item.tree_level == 1 ? !item?.children?.length : false,
        children: item.children
          ? replaceValuesAndLabels(item.children, valueField, labelField)
          : undefined
      }
    })
  }
  res.data.list = replaceValuesAndLabels(res.data.list, 'id', 'name')
  groups.value = res.data
  //@ts-ignore
  form.value.group_id = ''
  return groups.value
}
const businessList = ref<any>([])
const getBusiness = async () => {
  const { code, data } = await listApi({ page: 1, page_size: 1000, search_apply_state: 1 })
  if (code == 200) {
    businessList.value = data?.list.map((item: any) => {
      return {
        label: item.business_name,
        value: item.id
      }
    })
  }
}
const uploadConfig = ref<any>({})
const getUploadConfig = async () => {
  const { code, data } = await settingUploadApi()
  if (code == 200) {
    uploadConfig.value = data.content
  }
}

const listData: any = ref({
  list: [],
  total: 0
})
const pagination = ref({
  small: false,
  background: false,
  pagerCount: 7,
  layout: 'sizes, prev, pager, next, jumper, ->, total',
  pageSizes: [10, 20, 30, 40, 50, 100],
  disabled: false,
  hideOnSinglePage: false,
  total: listData?.value.total
})
const getList = async () => {
  const { code, data } = await toolsUploadListApi({
    ...form.value,
    group_id: form.value.group_id || 0,
    business_id: form.value.business_id || userStore.userInfo?.admin.business_id || 0
  })
  if (code == 200) {
    data.list.forEach((item: any) => {
      item.file_name_arr = fileNameArr(item.file_name)
      item.isSelected = selectArr.value.map((item) => item.id * 1).includes(item.id)
    })
    listData.value = data
    if (selectArr.value.length && data.list.length) {
      isSelectAll.value = data.list.filter((item) => item.isSelected).length == data.list.length
    } else {
      isSelectAll.value = false
    }
  }
}

watch(
  () => dialogTable.value,
  async (newValue, oldValue) => {
    if (!oldValue && newValue) {
      await Promise.all([getUploadConfig(), getGroups(), getBusiness()])

      selectArr.value = [...model.value]
      typeChange()
    }
  }
)
const dels = async () => {
  if (selectArr.value.length) {
    ElMessageBox.confirm('确定删除吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      const { code, message } = await toolsUploadDeleteApi({
        business_id: `${form.value.business_id}`,
        ids: selectArr.value.map((item: any) => item.id)
      })
      if (code === 200) {
        ElMessage({
          type: 'success',
          message: message
        })
        selectArr.value = []
        getList()
        getGroups()
      }
    })
  }
}
const to_group_id = ref()
const removeTo = () => {
  if (selectArr.value.length) {
    ElMessageBox.confirm('确定移动素材吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      const { code, message } = await toolsUploadRemoveApi({
        business_id: `${form.value.business_id}`,
        ids: selectArr.value.map((item: any) => item.id),
        group_id: to_group_id.value
      })
      if (code === 200) {
        ElMessage({
          type: 'success',
          message: message
        })
        selectArr.value = []
        dialogRemove.value = false
        getList()
        getGroups()
      }
    })
  }
}
const upload = ref<UploadInstance>()
const url = ref()
onMounted(() => {
  const base = import.meta.env.VITE_API_BASE_PATH
  url.value = `${base}/tools/upload/upload`
})
// eslint-disable-next-line vue/require-prop-types
const model = defineModel<any>()

if (!model.value || (model.value.length == 1 && model.value[0].id == '')) {
  model.value = []
}
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const isValid = ref(false)
const handleRemove: UploadProps['onRemove'] = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles)
}

const handleSuccess: UploadProps['onSuccess'] = (_, __, fileList) => {
  fileList.map((item: any) => {
    if (item?.response?.code == 200) {
      item.url = item.response.data.url
      item.id = item.response.data.id
      delete item.response
    }
  })
  ElMessage.success('资源已添加到素材库')
  getList()
}
const handleError: UploadProps['onError'] = (err, file, fileList) => {
  console.log(err, file, fileList)
}
const handleBeforeRemove = async () => {
  if (isValid.value) {
    return new Promise<boolean>((resolve, reject) => {
      ElMessageBox.confirm('确定删除该文件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          reject(false)
        })
    })
  } else {
    return true
  }
}

const handleBeforeUpload: UploadProps['beforeUpload'] = async (file) => {
  isValid.value = false
  if (limitSize.value) {
    if (file.size > limitSize.value * 1024) {
      ElMessage.error(`${file.name}文件大小不能超过${(limitSize.value / 1024).toFixed(2)}M`)
      return false
    }
  }
  if (props.sizes) {
    const img: { w: number; h: number } = await useImageSize(file)
    // 宽度
    if (props.sizes.w && img.w != props.sizes.w) {
      ElMessage.error(`${file.name}文件宽度必须为${props.sizes.w}`)
      return false
    }
    // 最小宽度
    if (props.sizes.minW && img.w < props.sizes.minW) {
      ElMessage.error(`${file.name}文件宽度不能小于${props.sizes.minW}`)
      return false
    }
    // 最大宽度
    if (props.sizes.maxW && img.w > props.sizes.maxW) {
      ElMessage.error(`${file.name}文件宽度不能大于${props.sizes.maxW}`)
      return false
    }
    // 高度
    if (props.sizes.h && img.h != props.sizes.h) {
      ElMessage.error(`${file.name}文件高度必须为${props.sizes.h}`)
      return false
    }
    // 最小高度
    if (props.sizes.minH && img.h < props.sizes.minH) {
      ElMessage.error(`${file.name}文件高度不能小于${props.sizes.minH}`)
      return false
    }
    // 最大高度
    if (props.sizes.maxH && img.h > props.sizes.maxH) {
      ElMessage.error(`${file.name}文件高度不能大于${props.sizes.maxH}`)
      return false
    }
  }
  isValid.value = true
}
const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
  if (props.accept === 'video/*') {
    window.open(uploadFile.url)
    return
  }
  if (props.accept === 'audio/*') {
    window.open(uploadFile.url)
    return
  }
  if (props.accept === 'application/pdf') {
    window.open(uploadFile.url)
    return
  }
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}
const handleExceed = (files, fileList) => {
  if (props.limit === 1) {
    upload.value!.clearFiles()
    const file = files[0] as UploadRawFile
    file.uid = genFileId()
    upload.value!.handleStart(file)
  } else {
    ElMessage.error(
      `当前限制选择 ${props.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`
    )
  }
}

defineExpose({
  openLibrary
})
</script>
<style lang="less" scoped>
:deep(.el-upload-list--picture-card) {
  --el-upload-list-picture-card-size: v-bind(props.cardsize);
}

:deep(.el-upload--picture-card) {
  --el-upload-picture-card-size: v-bind(props.cardsize);

  width: 90px !important;
  height: 20px;
  border: none;
}

:deep(.el-upload-dragger) {
  width: 90px;
  padding: 0 !important;
  border: none;
  box-sizing: border-box;
}

.hideUpload :deep(.el-upload--picture-card) {
  display: none;
}

.none-border :deep(.el-input__wrapper) {
  box-shadow: none;
}
</style>
